import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { AuthComponent } from './main/auth/auth.component';
 
const routes: Routes = [
   { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: '', component: LayoutComponent, canActivate: [AuthGuard], loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},
  { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule) },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
        {
            path: '',
            loadChildren:
                 () => import('./main/auth/auth.module').then(m => m.AuthModule)
        },
    ],

},
 
{
    path: 'chat',
    component: LayoutComponent, canActivate: [AuthGuard],
    children: [
        {
            path: '',
            loadChildren:
                 () => import('./main/mainpage/mainpage.module').then(m => m.MainPageModule)
        },
    ],
},
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
