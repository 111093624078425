import { Injectable } from "@angular/core";
import { HttpInterceptor } from '@angular/common/http';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    constructor() { }
    token = localStorage.getItem('token');
    intercept(req, next) {
        console.log('i m here');
        
        let tokenizedReq = req.clone({
            setHeaders: {
                Authorization: `${this.token}`
            }
        })

        return next.handle(tokenizedReq)

    }

}