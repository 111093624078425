<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © Alliance BTP .
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-sm-block">
                    Design & Develop by <a href="https://idev.codes/" target="_blank">IDEV.CODES</a> 
                </div>
            </div>
        </div>
    </div>
</footer>