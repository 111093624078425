<section id="teams-wrap" class="team-wrap p-t-b-80 light-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-12  section-header mb-5">
                <h2 class="font-weight-bold">Meet our Team</h2>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat ea quam laborum, ducimus,
                    laboriosam sint dolorum aspernatur libero.</p>

            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-3 col-sm-6 mb-lg-0  mb-4 ">
                <div class="card card-profile-1">
                    <div class="card-body text-center">
                        <div class="avatar box-shadow-2 mb-3">
                            <img src="./assets/images/landing/faces/15.jpg" alt="">
                        </div>
                        <h5 class="font-weight-medium card-title m-0">Jhon Doe</h5>
                        <p class="mt-0">UI/UX Designer</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <button class="btn btn-gradient half-button">Contact Jhon</button>
                        <div class="card-socials-simple mt-4">

                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-twitter twitter"></i>
                            </a>
                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-google google"></i>
                            </a>
                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-facebook facebook"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of col -->
            <div class="col-md-6 col-lg-3 col-sm-6 mb-lg-0  mb-4 ">
                <div class="card card-profile-1">
                    <div class="card-body text-center">
                        <div class="avatar box-shadow-2 mb-3">
                            <img src="./assets/images/landing/faces/12.jpg" alt="">
                        </div>
                        <h5 class="font-weight-medium card-title m-0">Jassica Pearl</h5>
                        <p class="mt-0">QA Tester</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <button class="btn btn-gradient half-button">Contact Jassica</button>
                        <div class="card-socials-simple mt-4">

                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-twitter twitter"></i>
                            </a>
                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-google google"></i>
                            </a>
                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-facebook facebook"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of col -->
            <div class="col-md-6 col-lg-3 col-sm-6  mb-lg-0 mb-md-0 mb-sm-0 mb-4 ">
                <div class="card card-profile-1">
                    <div class="card-body text-center">
                        <div class="avatar box-shadow-2 mb-3">
                            <img src="./assets/images/landing/faces/16.jpg" alt="">
                        </div>
                        <h5 class="font-weight-medium card-title m-0">Kara Smith</h5>
                        <p class="mt-0">Backend Developer</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <button class="btn btn-gradient half-button">Contact Kara</button>
                        <div class="card-socials-simple mt-4">

                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-twitter twitter"></i>
                            </a>
                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-google google"></i>
                            </a>
                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-facebook facebook"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of col -->

            <div class="col-md-6 col-lg-3 col-sm-6 mb-lg-0 mb-md-0 mb-sm-0  ">
                <div class="card card-profile-1">
                    <div class="card-body text-center">
                        <div class="avatar box-shadow-2 mb-3">
                            <img src="./assets/images/landing/faces/5.jpg" alt="">
                        </div>
                        <h5 class="font-weight-medium card-title m-0">Mike Danvers</h5>
                        <p class="mt-0">Frontend Developer</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <button class="btn btn-gradient half-button">Contact Mike</button>
                        <div class="card-socials-simple mt-4">

                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-twitter twitter"></i>
                            </a>
                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-google google"></i>
                            </a>
                            <a href="" class="text-20 mr-2">
                                <i class="eva eva-facebook facebook"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of col -->
        </div>
    </div>
</section>
