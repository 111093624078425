<ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselTileItems">
    <ngu-tile  *nguCarouselDef="let item; let i = index">


        <img class="img-responsive w-100 mb-4" [src]="item.photo" alt="Card image cap">





    </ngu-tile>

    <span NguCarouselPrev class="ngu_control_button half-button  leftRs btn btn-circle btn-white btn-shadow" [style.opacity]="myCarousel.isFirst ? 0.5:1"><i
        class="eva eva-chevron-left-outline"></i></span>
    <span NguCarouselNext class="ngu_control_button half-button  rightRs btn btn-circle btn-white btn-shadow" [style.opacity]="myCarousel.isLast ? 0.5:1"><i
        class="eva eva-chevron-right-outline"></i></span>



</ngu-carousel>