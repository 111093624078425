import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgOtpInputModule } from  'ng-otp-input';

import { ExtrapagesRoutingModule } from './extrapages-routing.module';

import { Page404Component } from './page404/page404.component';
import { CarouselSliderComponent } from './carousel-slider/carousel-slider.component';
import { TeamComponent } from './team/team.component';
import { LeftImageComponent } from './left-image/left-image.component';
 import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NguCarouselModule } from '@ngu/carousel';
import { SweetalertComponent } from './sweetalert/sweetalert.component';
@NgModule({
  declarations: [ Page404Component, CarouselSliderComponent, TeamComponent,LeftImageComponent,SweetalertComponent ],
  imports: [
    CommonModule,NguCarouselModule,
    CarouselModule,
    ExtrapagesRoutingModule,FormsModule,ReactiveFormsModule,
    NgOtpInputModule,
  ],
  exports:[TeamComponent,LeftImageComponent,SweetalertComponent]
})
export class ExtrapagesModule { }
